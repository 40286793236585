// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Link from "../../../../../styleguide/components/Link/Link.res.js";
import * as Routes_City from "../../../../../routes/common/Routes_City.res.js";
import * as Routes_State from "../../../../../routes/common/Routes_State.res.js";
import * as Routes_Country from "../../../../../routes/common/Routes_Country.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as CountriesDashboardNavbarScss from "./CountriesDashboardNavbar.scss";

var css = CountriesDashboardNavbarScss;

function CountriesDashboardNavbar(props) {
  var city = props.city;
  var state = props.state;
  var country = props.country;
  var __currentLink = props.currentLink;
  var currentLink = __currentLink !== undefined ? __currentLink : "Countries";
  var linkCss = function (link) {
    if (link === currentLink) {
      return Cx.cx([
                  css.linkContainer,
                  css.underlinedLink
                ]);
    } else {
      return css.linkContainer;
    }
  };
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx(Link.make, {
                                  href: Routes_Country.Dashboard.index,
                                  className: css.linkItem,
                                  children: "Countries"
                                }),
                            className: linkCss("Countries")
                          }),
                      JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx(Link.make, {
                                  href: Routes_State.Dashboard.index,
                                  className: css.linkItem,
                                  children: "States"
                                }),
                            className: linkCss("States")
                          }),
                      JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx(Link.make, {
                                  href: Routes_City.Dashboard.index,
                                  className: css.linkItem,
                                  children: "Cities"
                                }),
                            className: linkCss("Cities")
                          }),
                      country !== undefined ? (
                          state !== undefined ? (
                              city !== undefined ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: JsxRuntime.jsx(Link.make, {
                                                    href: Routes_City.Dashboard.edit(city.id),
                                                    className: css.linkItem,
                                                    children: "Edit City"
                                                  }),
                                              className: linkCss("Edit")
                                            }),
                                        JsxRuntime.jsx("div", {
                                              children: JsxRuntime.jsx(Link.make, {
                                                    href: Routes_City.show(city.slug, state.slug, country.slug),
                                                    className: css.linkItem,
                                                    children: "View City"
                                                  }),
                                              className: css.linkContainer
                                            })
                                      ]
                                    }) : JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: JsxRuntime.jsx(Link.make, {
                                                    href: Routes_State.Dashboard.edit(state.id),
                                                    className: css.linkItem,
                                                    children: "Edit State"
                                                  }),
                                              className: linkCss("Edit")
                                            }),
                                        JsxRuntime.jsx("div", {
                                              children: JsxRuntime.jsx(Link.make, {
                                                    href: Routes_State.show(state.slug, country.slug),
                                                    className: css.linkItem,
                                                    children: "View State"
                                                  }),
                                              className: css.linkContainer
                                            })
                                      ]
                                    })
                            ) : (
                              city !== undefined ? null : JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: JsxRuntime.jsx(Link.make, {
                                                    href: Routes_Country.Dashboard.edit(country.id),
                                                    className: css.linkItem,
                                                    children: "Edit Country"
                                                  }),
                                              className: linkCss("Edit")
                                            }),
                                        JsxRuntime.jsx("div", {
                                              children: JsxRuntime.jsx(Link.make, {
                                                    href: Routes_Country.show(country.slug),
                                                    className: css.linkItem,
                                                    children: "View Country"
                                                  }),
                                              className: css.linkContainer
                                            })
                                      ]
                                    })
                            )
                        ) : null
                    ],
                    className: css.barContainer
                  }),
              className: css.barWrapper
            });
}

var make = CountriesDashboardNavbar;

export {
  css ,
  make ,
}
/* css Not a pure module */
